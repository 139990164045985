<template>
  <v-container
    id="user-login"
    fluid
    tag="section"
  >
    <v-row justify="center">
      <v-col
        cols="12"
        md="8"
      >
        <base-material-card color="default">
          <template #heading>
            <div class="text-h3 font-weight-light">
              Login to HGK Uploader Dashboard
            </div>

            <div class="text-subtitle-1 font-weight-light">
              Access and manage your related media at FHNW HGK Campus.
            </div>
          </template>

          <v-simple-table class="pa-0">
            <tbody class="text-left">
              <tr>
                <td class="pr-0 pt-2 pl-0">
                  <v-btn
                    color="success"
                    @click="ssoCallback()"
                  >
                    Login with FHNW Login
                  </v-btn>
                </td>
              </tr>
            </tbody>
          </v-simple-table>
        </base-material-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
  const SSO_URL = process.env.NODE_ENV !== 'production' ? process.env.VUE_APP_SSO_URL_LOCAL : process.env.VUE_APP_SSO_URL
  const BASE_URL = process.env.NODE_ENV !== 'production' ? process.env.VUE_APP_BASE_URL_LOCAL : process.env.VUE_APP_BASE_URL
  export default {
    data () {
      return {
        firstname: null,
        email: null,
        password: null,
      }
    },
    methods: {
      ssoCallback () {
        window.location.href =
          SSO_URL +
          '?callback=' +
          BASE_URL +
          '?sso-auth=_JWT_'
      },
    },
  }
</script>

<style></style>
